/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.author {
  text-align: right;
  font-weight: bold;
}

.quote {
  text-align: center;
  font-style: italic;
}

p {
  text-align: justify;
}

p a {
  color: #002ba3 !important;
  font-weight: bold;
}

p a:hover {
  text-decoration: underline;
}

strong {
  color: #110066;
}

.profile-logo {
  width: auto;
  height: 6rem;
}

.dark-background {
  background-color: #110066;
}

.gray-background {
  background-color: #f3f3f3;
}

.text-white {
  color: white !important;
}

.image-border {
  border: 0.3rem solid #fff;
}

img {
  border-radius: 0.25rem;
}

.not-round {
  border-radius: 0;
}

.align-right {
  align-items: end;
}

.align-center {
  align-items: center;
}
